<template>
  <div>
    <Header />
    <section
      id="iq-home"
      class="banner-03 iq-bg iq-bg-fixed iq-over-black-80"
      style="background: url(assets/images/banner/bg.jpg)"
    ></section>
    <Content />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Content from "./Content.vue";
import Footer from "@/components/layouts/Footer.vue";
import Header from "@/components/layouts/Header.vue";
import Contact from "@/components/layouts/Contact.vue";

export default {
  name: "TermAndCondition",
  components: {
    Header,
    Content,
    Contact,
    Footer,
  },
  mounted() {
    document.title = "Job and Internship Opening - Carbon Addons";
    this.$gtag.pageview(window.location.href);
  },
};
</script>
